.container {
  height: 100%;
  position: relative;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    z-index: 2;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;

    .column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .wrapperUpdate {
        display: grid;
        grid-template-columns: 180px auto;
        align-items: center;
        p {
          color: #373f50;
          font-size: 16px;
          font-weight: 600;
        }
        .summaryWrapper {
          display: flex;
          align-items: center;
          max-width: 300px;
          border-bottom: 1px solid #000;
          .updateColumnsOne {
            display: flex;
            align-items: center;
            span {
              font-size: 14px;
              margin-right: 3px;
              b {
                font-weight: 500;
                cursor: pointer;
              }
            }
            input {
              margin-bottom: -3px;
            }
          }
          .updateColumnsTwo {
            height: 37px;
            display: flex;
            align-items: center;
            span {
              font-size: 14px;
              margin-right: 3px;
              b {
                font-weight: 500;
                cursor: pointer;
              }
            }
            &:nth-child(2) {
              margin-left: 30px;
            }
          }
        }
      }
    }

    .radio {
      margin-top: 50px;
    }
    .carousel {
      display: grid;
      grid-template-columns: 180px auto;
      margin-top: 50px;
      p {
        color: #373f50;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
