.wrapper {
  height: 100vh;
  width: 100%;
  background-color: rgba(213, 227, 206, 0.694);
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  .close {
    position: absolute;
    right: -20px;
    top: -20px;
    transform: rotate(30deg);
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(15deg);
    width: 400px;
    .images {
      display: inline-block;
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }

    .video {
      display: inline-block;
      width: 100%;
      height: 400px;
      object-fit: cover;
    }

    .block {
      width: 400px;
      height: 400px;
      background-color: #fff;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .blockWrapper {
        text-align: center;

        .errors {
          color: red;
          font-size: 14px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .done {
          color: rgb(81, 201, 123);
          font-size: 14px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .comments {
          position: absolute;
          bottom: 50px;
          left: 60px;

          display: flex;
          align-items: center;
          justify-content: center;

          button {
            font-size: 14px;
            margin-right: 20px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
              opacity: 0.6;
            }
          }
        }
        p:not(:first-child) {
          margin-top: 10px;
        }
      }
    }

    .rotate {
      cursor: pointer;
      width: 40px;
      position: absolute;
      right: -30px;
      bottom: -30px;
      z-index: 9;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
