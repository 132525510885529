.wrapper {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(191, 215, 181, 0.71);
  z-index: 2;

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px 60px;
    text-align: center;
    border: 1px solid #000;

    .close {
      width: 20px;
      cursor: pointer;
    }

    .errorContainer {
      width: 400px;
      h3 {
        p {
          color: #000;
          width: 100%;
          font-size: 28px;
          font-weight: 400;
          width: 300px;
          margin: 0 auto;
          margin-bottom: 20px;
          line-height: 1.5;
        }
        span {
          color: #7debbb;
          font-weight: 600;
        }
      }
      p {
        color: red;
      }
    }

    .changerIcon {
      img {
        cursor: pointer;
        width: 25px;
        height: 25px;
      }
    }

    .blocks {
      h1 {
        font-size: 28px;
        font-weight: bold;
        width: 300px;
        margin: 0 auto;
        margin-bottom: 20px;
        line-height: 1.5;
      }
    }

    h1 {
      font-size: 16px;
      font-weight: 400;
      width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;
      line-height: 1.5;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      margin-top: 20px;
    }

    h3 {
      font-size: 28px;
      font-weight: bold;
      width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;
      line-height: 1.5;
    }
  }
}
