.wrapper {
  position: relative;
  width: 450px;

  .el {
    max-width: 100px;
    height: 100px;
    background-color: #e6ebe3;

    b {
      position: absolute;
      top: 0;
      width: 100px;
      text-align: center;
      display: flex;
      justify-content: center;
      display: none;
      background-color: rgb(230, 211, 210);
    }

    &:hover {
      span {
        transition: all 0.2s;

        max-width: 100px;
        height: 100px;
        position: absolute;
        z-index: 2;
        top: 0;
        color: red;
        background-color: #ffffff50;
        font-size: 13px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      b {
        display: block;
      }
    }

    img {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      cursor: pointer;
      border: 1px solid #b9b9b9;
      transition: all 0.5s;

      &:hover {
        opacity: 0.6;
      }
    }

    video {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      cursor: pointer;
      border: 1px solid #b9b9b9;
      transition: all 0.5s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .arrowLeft {
    position: absolute;
    top: 40px;
    left: -35px;
    width: 15px;
    height: 15px;
    z-index: 1;
    transform: rotate(90deg) translateY(-50%);
    cursor: pointer;
  }

  .arrowRight {
    position: absolute;
    top: 40px;
    right: -35px;
    width: 15px;
    height: 15px;
    z-index: 1;
    transform: rotate(-90deg) translateY(-50%);
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
