.buttonPanel {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: right;
  
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 20px;
  
      &:hover {
        opacity: 0.6;
      }
    }
  }
  
  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  
    background: url("../../../../assets/fragment/book.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  
    padding: 30px;
    display: flex;
  
    .texts {
      width: 50%;
      height: 93%;
      padding: 0 20px;
  
      h1 {
        font-size: 16px;
        text-align: center;
      }
  
      .head {
        font-size: 14px;
        margin-top: 20px;
  
        time {
          font-size: 14px;
        }
      }
  
      .subHead {
        font-size: 14px;
      }
  
      .description {
        font-size: 14px;
        margin-top: 20px;
        line-height: 1.5;
        overflow-y: auto;
        height: calc(100% - 150px);
      }
    }
  
    .textsHebrew {
      width: 50%;
      height: 93%;
      padding: 0 20px;
      direction: rtl;
      unicode-bidi: bidi-override;
  
      h1 {
        font-size: 16px;
        text-align: center;
      }
  
      .head {
        font-size: 14px;
        margin-top: 20px;
      }
  
      .description {
        font-size: 14px;
        margin-top: 20px;
        line-height: 1.5;
      }
    }
  
    .picture {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 93%;
      width: 50%;
      padding: 0 20px;
      position: relative;
  
      // scrollbars
  
      scrollbar-width: thin;
      scrollbar-color: green transparent;
  
      &::-webkit-scrollbar {
        width: 5px;
        background-color: #fff;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: #60605f;
        cursor: pointer;
      }
  
      .loading {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(0, -50%);
      }
  
      .myMasonryGrid {
        display: flex;
        width: auto;
      }
  
      .myMasonryGridColumn {
        background-clip: padding-box;
      }
  
      .myMasonryGridColumn > img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        cursor: pointer;
        border: 1px solid #b9b9b9;
        opacity: 0.8;
        transition: all 0.5s;
  
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  
  .arrowContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    .button {
      z-index: 2;
      cursor: pointer;
  
      &:hover {
        opacity: 0.6;
      }
  
      .arrowLeft {
        width: 20px;
        position: absolute;
        top: -60px;
        left: -20px;
        transform: rotate(90deg);
      }
      .arrowRight {
        width: 20px;
        position: absolute;
        top: -60px;
        right: -20px;
        transform: rotate(-90deg);
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    .button {
      z-index: 2;
      cursor: pointer;
      color: #373f50;
      font-size: 16px;
      font-weight: 600;
  
      &:hover {
        opacity: 0.6;
      }
    }
    .selected {
      color: #bfd7b5;
      font-size: 30px;
      font-weight: 600;
    }
  }
  