.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    width: 450px;
    position: relative;

    .line {
      display: flex;
      width: 50%;
      justify-content: space-evenly;

      .element {
        text-align: center;
        margin-right: 15px;

        p {
          margin-top: 10px;
        }
        .block {
          background: color(srgb 0.9445 0.9695 0.9389);
          border: 1px solid #000;
          height: 150px;
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            height: 150px;
            width: 150px;

            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 40px;
              height: 40px;
            }
          }

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

input[type="file"]::before {
  content: "";
  display: inline-block;
  background-size: 100%;
  width: 30px;
  height: 30px;
}

/* стилизовать кнопку */
input[type="file"] {
  height: 100px;
  width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
