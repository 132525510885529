.wrapper {
  display: grid;
  grid-template-columns: 180px auto;
  margin-top: 17px;
  font-size: 16px;
  align-items: end;
  label {
    color: #373f50;
    font-weight: 600;
  }
}
