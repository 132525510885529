.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 55px 50px;

  .head {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    cursor: pointer;

    .burgerColumn {
      .burgerLine:nth-child(1) {
        background-color: #bfd7b5;
        height: 3px;
        width: 30px;
      }
      .burgerLine:nth-child(2) {
        background-color: #bfd7b5;
        height: 3px;
        width: 30px;
        margin-top: 6px;
      }
      .burgerLine:nth-child(3) {
        background-color: #bfd7b5;
        height: 3px;
        width: 30px;
        margin-top: 6px;
      }
    }

    .name {
      margin-left: 20px;
      color: #1486a3;
      font-size: 16px;
      margin-left: 20px;
    }
  }

  .body {
    height: calc(100vh - 135px);
    display: flex;
    align-items: center;

    .menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
          width: 10px;
          height: 10px;
        }
      }
      li {
        margin-top: 25px;
        font-weight: 600;
        a {
          text-decoration: none;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.activeLink {
  color: #bfd7b5;
}

.normalLink {
  color: #000;
}
