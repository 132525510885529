.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 200px);
  .head {
    display: flex;
    justify-content: center;
  }

  .body {
    display: flex;
    justify-content: space-between;

    .fields {
      display: flex;
      flex-direction: column;
      .error {
        height: 20px;
        color: red;
        margin-top: 15px;
        font-size: 13px;
        text-align: right;
      }

      .success {
        height: 20px;
        color: rgb(48, 201, 97);
        margin-top: 15px;
        font-size: 13px;
        text-align: right;
      }
    }
  }

  .buttons {
    height: 20px;
  }
}
