.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 650px;
  padding: 55px 50px;

  .head {
    cursor: pointer;
    .burgerLine:nth-child(1) {
      background-color: #bfd7b5;
      height: 3px;
      width: 30px;
    }
    .burgerLine:nth-child(2) {
      background-color: #bfd7b5;
      height: 3px;
      width: 30px;
      margin-top: 6px;
    }
    .burgerLine:nth-child(3) {
      background-color: #bfd7b5;
      height: 3px;
      width: 30px;
      margin-top: 6px;
    }
  }

  .texts {
    max-width: 250px;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.5;

    p:nth-child(2) {
      margin-top: 20px;
    }

    p {
      span {
        font-size: 16px;
        font-weight: 600;
        color: rgba(191, 215, 181, 0.7098039216);
        margin-top: 10px;
      }
    }
  }

  .body {
    .error {
      height: 20px;
      color: red;
      margin-top: 15px;
      font-size: 13px;
    }
  }

  .footer {
    height: 20px;
    .loader {
      display: flex;
      justify-content: right;
    }
  }
}
