body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

div {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  object-fit: cover;
}

a {
  text-decoration: none;
  margin: 0;
  padding: 0;
}

button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}

input {
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  border: none;
}

textarea {
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  border: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  list-style: none;
  color: #000;
  margin: 0;
  padding: 0;
}
:where(.css-dev-only-do-not-override-htwhyh).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: inherit;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #373f50;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #373f50 !important;
  opacity: 0.6;
}

.ant-picker-input > input::placeholder {
  color: #000 !important;
}

.my-select .ant-select-selection-item,
.my-select .ant-select-selection-placeholder {
  font-size: 16px; 
}