.wrapper {
  height: 100vh;
  width: 100%;
  background-color: rgba(213, 227, 206, 0.694);
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;

  .container {
    border: 1px solid #000;
    width: 600px;
    height: 350px;
    background-color: #fff;
    padding: 30px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .close {
      position: sticky;
      top: 20px;
      right: 20px;

      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .head {
      p,
      h1 {
        text-align: center;
        display: block;
      }
    }

    .column {
      margin: 0 auto;
    }
  }
}

.messages {
  height: 30px;

  .error {
    display: block;
    color: red;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .done {
    display: block;
    color: rgb(80, 188, 92);
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
}
