.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0, -50%);
    z-index: 2;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: right;
}
