.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: #fff;
    border: 1px solid #707070;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    width: 600px;

    .error {
      color: red;
      font-size: 14px;
      text-align: center;
      max-width: 300px;
    }

    .block {
      background: color(srgb 0.9445 0.9695 0.9389);
      border: 1px solid #000;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

input[type="file"]::before {
  content: "";
  display: inline-block;
  background-size: 100%;
  width: 30px;
  height: 30px;
}

/* стилизовать кнопку */
input[type="file"] {
  height: 100px;
  width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/menu/create.png");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
