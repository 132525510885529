.messages {
  height: 30px;

  .error {
    display: block;
    color: red;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .done {
    display: block;
    color: rgb(80, 188, 92);
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
}
