.wrappers {
  width: 200px;
  max-height: 200px;
  .blocks {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    position: relative;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    input {
      display: flex;
      flex-direction: column;
      width: 200px;
      height: 200px;
      cursor: pointer;
    }

    input[type="file"]::-webkit-file-upload-button {
      visibility: hidden;
    }
    input[type="file"]::before {
      content: "";
      display: inline-block;
      background: url("../../../../assets/fragment/upload.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 200px;
      height: 200px;
    }

    /* стилизовать кнопку */
    input[type="file"] {
      background-color: transparent;
      border: none;
      font-size: 0;
      cursor: pointer;
      outline: none;
    }
    label {
      display: flex;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      width: 200px;
      height: 200px;
      b {
        font-weight: 400;
        display: flex;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        bottom: -20px;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
