.wrapper {
  padding: 55px 50px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .row {
    margin: 20px 0;

    p {
      font-weight: bold;
    }
  }

  .clear {
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.6;
    }
    p {
      display: block;
      font-size: 10px;
    }
    img {
      margin-left: 10px;
      width: 10px;
      height: 10px;
    }
  }

  .head {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    cursor: pointer;

    .burgerColumn {
      .burgerLine:nth-child(1) {
        background-color: #bfd7b5;
        height: 3px;
        width: 30px;
      }
      .burgerLine:nth-child(2) {
        background-color: #bfd7b5;
        height: 3px;
        width: 30px;
        margin-top: 6px;
      }
      .burgerLine:nth-child(3) {
        background-color: #bfd7b5;
        height: 3px;
        width: 30px;
        margin-top: 6px;
      }
    }

    .name {
      margin-left: 20px;
      color: #1486a3;
      font-size: 16px;
      margin-left: 20px;
    }
  }

  .middle {
    position: absolute;
    top: 50%;
  }
}
