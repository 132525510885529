.wrappers {
  height: 100%;
  width: 100%;
  position: relative;

  .containers {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    margin: 0 auto;

    .slides {
      max-width: 180px;
      height: 250px;
      position: relative;
      margin: 10px 0;
      transition: all 0.5s;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      img {
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
      .elements {
        position: absolute;
        top: 0;
        width: 180px;
        height: 250px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}

.arrowLeft {
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
  position: absolute;
  left: -50px;
  top: 50%;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    opacity: 0.5;
  }
}

.arrowRight {
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  position: absolute;
  right: -50px;
  top: 50%;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    opacity: 0.5;
  }
}
