.wrapper {
  display: flex;
  align-items: center;

  input::placeholder {
    opacity: 1;
    border-bottom: none;
  }

  input {
    border-bottom: none;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
