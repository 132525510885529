.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;

  .background {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .container {
    padding: 100px 50px;
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;

    .dateOfCreate {
      padding: 55px 50px;
      position: fixed;
      bottom: 0;
      display: block;
      margin-top: auto;
      color: #fff;
      font-size: 40px;
    }

    .dateOfDeath {
      padding: 55px 50px;
      position: fixed;
      bottom: 55px;
      display: block;
      margin-top: auto;
      color: #fff;
      font-size: 40px;
      margin-top: 20px;
    }

    .logo {
      padding: 0 50px;
      position: fixed;
      right: 0;
      top: 30px;
      display: flex;
      align-items: center;
      p {
        font-size: 20px;
        font-weight: 300;
      }
      img {
        width: 25px;
        height: 25px;
        margin-left: 10px;
      }
    }
  }
}
