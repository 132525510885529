.wrapper {
  display: flex;
  flex-direction: column;
  label {
    display: inline-block;
    color: #373f50;
    font-size: 16px;
    font-weight: 600;
  }
  input {
    border-bottom: 2px solid #373f50;
    margin-top: 17px;
    font-size: 16px;

    &:hover {
      opacity: .6;
    }
  }
}
