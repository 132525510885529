.wrapper {
    display: grid;
    align-items: end;
    margin-top: 17px;
  
    label {
      display: inline-block;
      color: #373f50;
      font-size: 16px;
      font-weight: 600;
    }
  
    input {
      &:hover {
        border-bottom: 2px solid #000;
      }
    }
  }