.buttonPanel {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: right;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 20px;

    &:hover {
      opacity: 0.6;
    }
  }

  video {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 20px;

    &:hover {
      opacity: 0.6;
    }
  }
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  background: url("../../../../assets/fragment/book.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  padding: 30px;
  display: flex;

  .texts {
    width: 50%;
    height: 93%;
    padding: 0 20px;

    h1 {
      font-size: 16px;
      text-align: center;
    }

    .head {
      font-size: 14px;
      margin-top: 20px;

      time {
        font-size: 14px;
      }
    }

    .subHead {
      font-size: 14px;
    }

    .description {
      font-size: 14px;
      margin-top: 20px;
      line-height: 1.5;
      overflow-y: auto;
      height: calc(100% - 150px);
    }
  }

  .textsHebrew {
    width: 50%;
    height: 93%;
    padding: 0 20px;
    direction: rtl;
    unicode-bidi: bidi-override;

    h1 {
      font-size: 16px;
      text-align: center;
    }

    .head {
      font-size: 14px;
      margin-top: 20px;
    }

    .description {
      font-size: 14px;
      margin-top: 20px;
      line-height: 1.5;
    }
  }

  .picture {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 93%;
    width: 50%;
    padding: 0 20px;
    position: relative;

    // scrollbars

    scrollbar-width: thin;
    scrollbar-color: green transparent;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #60605f;
      cursor: pointer;
    }

    .loading {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(0, -50%);
    }

    .myMasonryGrid {
      display: flex;
      width: auto;
    }

    .myMasonryGridColumn {
      background-clip: padding-box;
      position: relative;

      .playButton {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url("../../../../assets/icons/play-icon.png");
        width: 50px;
        height: 50px;
        background-size: 100%;
        cursor: pointer;
        transition: all .5s;

        &:hover {
          opacity: .6;
        }
      }
    }

    .myMasonryGridColumn > img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      cursor: pointer;
      border: 1px solid #b9b9b9;
      opacity: 0.8;
      transition: all 0.5s;

      &:hover {
        opacity: 1;
      }
    }

    .myMasonryGridColumn > video {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      cursor: pointer;
      border: 1px solid #b9b9b9;
      opacity: 0.8;
      transition: all 0.5s;

      &:hover {
        opacity: 1;
      }
    }

    .myMasonryGridColumnRed {
      background-clip: padding-box;
    }

    .myMasonryGridColumnRed > img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      cursor: pointer;
      border: 2px solid red;
      opacity: 0.8;
      transition: all 0.5s;

      &:hover {
        opacity: 1;
      }
    }

    .myMasonryGridColumnRed > video {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      cursor: pointer;
      border: 2px solid red;
      opacity: 0.8;
      transition: all 0.5s;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.arrowContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  .button {
    z-index: 2;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    .arrowLeft {
      width: 20px;
      position: absolute;
      top: -60px;
      left: -20px;
      transform: rotate(90deg);
    }
    .arrowRight {
      width: 20px;
      position: absolute;
      top: -60px;
      right: -20px;
      transform: rotate(-90deg);
    }
  }
}
.buttonContainer {
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding: 0 30px;

  .button {
    z-index: 2;
    cursor: pointer;
    color: #373f50;
    font-size: 16px;
    font-weight: 600;
    min-width: 25%;

    &:hover {
      opacity: 0.6;
    }
  }
  .selected {
    color: #bfd7b5;
    font-size: 30px;
    font-weight: 600;
  }
}

// scrollbars
::-webkit-scrollbar {
  height: 12px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

/* Стилизуем ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Цвет ползунка скроллбара */
  border-radius: 5px; /* Закругление углов ползунка скроллбара */
}

/* Стилизуем кнопки прокрутки */
::-webkit-scrollbar-button {
  background-color: none; /* Цвет кнопок прокрутки */
}

/* Стилизуем углы скроллбара */
::-webkit-scrollbar-corner {
  background-color: none; /* Цвет углов скроллбара */
}
