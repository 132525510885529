.container {
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    z-index: 2;
  }

  .error {
    font-size: 14px;
    color: red;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .redirect {
    display: block;
    position: relative;
    p {
      display: block;
      position: absolute;
      top: 0;
      height: 130px;
      width: 100%;
      cursor: pointer;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;

    .column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .radio {
      margin-top: 50px;
    }
    .carousel {
      display: grid;
      grid-template-columns: 180px auto;
      margin-top: 50px;
      p {
        color: #373f50;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
