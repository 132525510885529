.wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(213, 227, 206, 0.694);
  top: 0;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    max-width: 500px;
    max-height: 700px;
    overflow-y: auto;
    margin-left: auto;
    margin-right: 50px;

    .element {
      position: relative;
      span {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        font-weight: bold;
        background-color: #cbc7c760;
        width: 100%;
        height: 20px;
        z-index: 2;
        display: none;
      }

      &:hover {
        span {
          display: flex;
          justify-content: center;
          cursor: pointer;
        }
      }

      img {
        width: 100%;
        height: 70px;
        object-fit: cover;
        cursor: pointer;
      }

      video {
        width: 100%;
        height: 70px;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}

.images {
  border: 2px solid red;
}
