.wrapper {
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(191, 215, 181, 0.71);
  z-index: 2;

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    .changerIcon {
      position: absolute;
      left: 30px;
      top: 20px;
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .block {
      background-color: #fff;
      border: 1px solid #707070;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      max-width: 600px;
      min-height: 350px;
      min-width: 600px;
      padding: 30px;
    }

    .blocks {
      background-color: #fff;
      border: 1px solid #707070;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 600px;
      min-height: 350px;
      min-width: 600px;
      padding: 30px;

      h1 {
        text-align: center;
      }
    }
  }
}
