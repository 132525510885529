.wrapper {
  display: grid;
  align-items: end;
  grid-template-columns: 180px auto;
  align-items: start;
  margin-top: 17px;
  label {
    display: inline-block;
    color: #373f50;
    font-size: 16px;
    font-weight: 600;
  }
  .textarea {
    border-bottom: 2px solid #373f50;
    margin-top: 17px;

    font-size: 16px;
    height: 100px;
    overflow-y: scroll;

    &:hover {
      opacity: 0.6;
    }
  }
}