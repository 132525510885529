.wrapper {
  width: 150px;
  max-height: 150px;
  cursor: pointer;
  .videoUrl {
    text-align: center;

    video {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }
  }
}

.hiddenInput {
  position: absolute;
  visibility: hidden;
}
.blocks {
  width: 150px;
  height: 150px;
  text-align: center;
  position: relative;

  video {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  input {
    display: flex;
    flex-direction: column;
    width: 150px;
    max-height: 150px;
    cursor: pointer;
  }

  input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
  }
  input[type="file"]::before {
    content: "";
    display: inline-block;
    background: url("../../assets/fragment/upload.png");
    background-size: 100%;
    width: 150px;
    height: 150px;
  }

  /* стилизовать кнопку */
  input[type="file"] {
    background-color: transparent;
    border: none;
    font-size: 0;
    cursor: pointer;
    outline: none;
    width: 150px;
    height: 150px;
  }

  label {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: #000;
    font-weight: 300;
    position: absolute;
    left: 35px;
    bottom: -30px;
  }

  button {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: #000;
    font-weight: 300;
    font-size: 16px;

    &:hover {
      opacity: 0.6;
    }
  }

  &:hover {
    label,
    button {
      opacity: 0.6;
    }
  }
}
