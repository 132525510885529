.wrapper {
    height: 100vh;
    width: 100%;
    background-color: rgba(213, 227, 206, 0.694);
    display: flex;
    align-items: center;
    justify-content: center;
  
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 500px;
      .images {
        display: inline-block;
        max-width: 100%;
        height: auto;
        object-fit: cover;
      }
  
      .block {
        width: 550px;
        height: 350px;
        padding: 30px;
        background-color: #fff;
        border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
  
        .participantWrapper {
          display: flex;
          flex-direction: column;
          text-align: center;
        }
  
        .closePopup {
          position: absolute;
          top: 20px;
          left: 20px;
          cursor: pointer;
  
          img {
            width: 20px;
            height: 20px;
          }
        }
  
        .blockWrapper {
          text-align: center;
          width: 400px;
  
          h1 {
            font-size: 17px;
            font-weight: 400;
          }
  
          .memberWrapper {
            display: grid;
            grid-template-columns: 150px 1fr;
            text-align: left;
            align-items: end;
            margin-top: 20px;
  
            p {
              font-weight: bold;
            }
          }
  
          .socialWrapper {
            display: grid;
            grid-template-columns: 150px 1fr;
            text-align: left;
            margin-top: 40px;
  
            p {
              font-weight: bold;
            }
  
            .socialContent {
              display: flex;
              align-items: end;
  
              .message {
                font-size: 10px;
                margin-left: 10px;
                color: rgb(119, 125, 124);
              }
  
              .copy {
                background-color: #f1f7ef;
                border: 1px solid #707070;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-left: 10px;
  
                &:hover {
                  opacity: 0.6;
                }
  
                img {
                  width: 25px;
                  height: 25px;
                }
              }
  
              .whatsapp {
                background-color: #f1f7ef;
                border: 1px solid #707070;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
  
                &:hover {
                  opacity: 0.6;
                }
  
                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  