.wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: green transparent;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: calc(100vh - 300px);
  z-index: 2;

  .element {
    position: relative;
    max-width: 140px;
    z-index: 1;

    .playButton {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 100%);
      background: url("../../../../assets/icons/play-icon.png");
      width: 50px;
      height: 50px;
      background-size: 100%;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        opacity: 0.6;
      }
    }

    .red {
      border: 2px solid red;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      font-weight: bold;
      background-color: #cbc7c760;
      width: 100%;
      height: 20px;
      z-index: 2;
      display: none;
    }

    &:hover {
      z-index: 2;
      span {
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }

    img {
      position: absolute;
      max-width: 140px;
      height: auto;
      cursor: pointer;

      transition: box-shadow 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 10px 15px 1px rgba(0, 0, 0, 0.173);
      }
    }

    video {
      position: absolute;
      width: 100%;
      height: 170px;
      object-fit: cover;
      cursor: pointer;

      transition: box-shadow 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 10px 15px 1px rgba(0, 0, 0, 0.173);
      }
    }
  }
}

.additional {
  width: 170px;
  height: 170px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;

  .picture {
    width: 170px;
    height: 170px;
    object-fit: cover;
  }
}
