.wrapper {
  display: grid;
  align-items: end;
  grid-template-columns: 180px auto;
  margin-top: 17px;
  font-size: 16px;
  align-items: start;
  label {
    color: #373f50;
    font-weight: 600;
  }

  .container {
    display: flex;
    align-items: end;
    flex-direction: column;

    .preview {
      width: 100%;
      height: 100%;
      max-height: 85px;
      overflow-y: auto;

      .item {
        &:not(:first-child) {
          margin: 10px 0;
        }
      }
    }

    .panel {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .updateField {
        border-bottom: 2px solid #000;
        font-size: 16px;
        width: 100%;
      }

      .plus {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: right;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
