.wrapper {
  height: 100%;
  .content {
    display: grid;
    grid-template-columns: 330px 330px;
    justify-content: center;
    gap: 20px;
    height: 100%;
    overflow-y: scroll;

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(50%, -50%);
    }

    .item {
      position: relative;
      max-width: 330px;
      height: 330px;
      cursor: pointer;
      transition: all 0.3s;

      .contentWrapper {
        display: flex;
        .leftContent {
          position: absolute;
          top: 20px;
          left: 20px;

          display: flex;
          flex-direction: column;

          .row {
            display: flex;
            font-size: 7px;
            margin-top: 5px;

            // scrollbars

            scrollbar-width: thin;
            scrollbar-color: green transparent;

            &::-webkit-scrollbar {
              width: 3px;
              background-color: #fff;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #60605f;
              cursor: pointer;
            }

            .buttonWrapper {
              width: 100%;
              display: flex;
              justify-content: right;
              button {
                font-size: 8px;
                color: #1486a3;
                margin-left: 10px;
              }
            }

            .imageWrapper {
              overflow-x: auto;
              width: 260px;
              display: flex;
              margin-top: 10px;
              img {
                width: 40px;
                height: 40px;
                margin-right: 10px;
              }
              video {
                width: 40px;
                height: 40px;
                margin-right: 10px;
              }
            }

            .circleWrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 10px;
              margin-top: 10px;

              .circle {
                width: 10px;
                height: 10px;
                border: 0.5px solid #000;
                border-radius: 100%;

                display: flex;
                justify-content: center;
                align-items: center;

                i {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 5px;
                }
              }

              .circleText {
                margin-left: 5px;
              }
            }
            p {
              width: 100px;
              font-weight: bold;
            }
            span {
              border-bottom: 1px solid #000;
              width: 80px;
            }
          }
        }

        .rightContent {
          position: absolute;
          right: 20px;
          top: 20px;
          height: 60px;
          width: 60px;
          background-color: #fff;

          span {
            position: absolute;
            bottom: -10px;
            left: 13px;
            font-size: 7px;
            font-weight: bold;
          }
        }
      }

      &:hover {
        opacity: 0.6;

        p {
          color: rgb(88, 88, 87);
        }
      }

      p {
        margin-bottom: 3px;
      }

      img {
        display: inline-block;
        max-width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  .bottom {
    padding: 20px 0;
    display: flex;

    span {
      margin-right: 20px;
      font-weight: bold;
    }

    p {
      margin-left: 20px;
    }
  }
}
