.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    width: 650px;
    padding: 55px 50px;
  
    .head {
      display: flex;
      a:nth-child(2) {
        margin-left: 40px;
      }
      a {
        color: #29a7c6;
        font-size: 17px;
      }
    }
  
    .body {
      .error {
        height: 20px;
        color: red;
        margin-top: 15px;
        font-size: 13px;
      }
    }
  
    .footer {
      height: 20px;
      .loader {
        display: flex;
        justify-content: right;
      }
    }
  }